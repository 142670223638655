import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { IProfile } from '@/interfaces/suite8';
import {
  IOrder,
  IOrderItem,
  IPackages,
  ISearchForm,
  ISearchReservationForm
} from '@/interfaces/bookingContext';
import jwt_decode from 'jwt-decode';
import { getCurrentWebsite, getPropertiesBySearchForm, website } from './helpers';
import dayjs from 'dayjs';
import { AxiosResponse } from 'axios';
import { IProperty } from '@/interfaces/huWebsite';
import i18next from "i18next";
import { endpoints } from '@/utils/axios';

export const headers = {
  'Content-type': 'application/json',
  Accept: 'application/json',
  'Accept-Language': i18next.language || 'en'
};

export const configBooking = () => {
  let token = localStorage.getItem('token_booking');

  if (token) {
    const decoded: any = jwt_decode(token.replace('Bearer ', ''));
    if (dayjs().isAfter(dayjs.unix(decoded.exp))) {
      localStorage.removeItem('token_booking');
      token = null;
    }
  }

  const config: AxiosRequestConfig = {
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': window.current_lang || 'en',
      Authorization: token || ''
    },
    validateStatus: function (status) {
      return status >= 200 && status < 300; // default
    }
    // withCredentials: true
  };

  if (!token) {
    // @ts-ignore
    delete config.headers.Authorization;
    delete axios.defaults.headers.common['Authorization'];
  }
  return config;
};

export const configBookingWithCredentials = () => {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': window.current_lang || 'en',
      Cookie: window.document.cookie
    },
    validateStatus: function (status) {
      return status >= 200 && status < 300; // default
    },
    withCredentials: true
  };

  return config;
};

export interface IGetRoomTypeDetails {
  url: string;
  headers?: object;
}

export const getRoomTypeDetails = ({ url }: IGetRoomTypeDetails) => {
  return axios.get(url, { headers });
};

export interface IGetPackages {
  url: string;
  headers?: object;
}

export const getPackages = ({ url }: IGetPackages) => {
  return axios.get(url);
};

/*
export const getChildCategories = (website: string) => {
  const property = propertyByWebsite(website);
  return axios.get(`https://hc.azure-api.net/fidelio/v2/childrencategories/${property}`, {
    headers
  });
};

export const getNationalities = () => {
  return axios.get(`https://hc.azure-api.net/v2/countrylist/static`, {
    headers
  });
};*/

export const createOrUpdateProfile = (property: string, data: IProfile) => {
  const requestData = { ...data };

  if (requestData.Telephone) {
    requestData.Telephone = [
      {
        name: 'Telephone',
        attr: 'TEL',
        value: requestData.Telephone,
        Primary: 1
      }
    ];
  } else {
    delete requestData.Telephone;
  }

  if (requestData.Email) {
    requestData.Email = [
      {
        name: 'Telephone',
        attr: 'EMA',
        value: requestData.Email,
        Primary: 1
      }
    ];
  } else {
    delete requestData.Email;
  }

  if (requestData.Birthday) {
    requestData.Birthday = dayjs(requestData.Birthday).format('DD.MM.YYYY');
  }

  if (requestData.ProfileID) {
    return axios.put(
      `${endpoints.profiles}/${property}/${requestData.ProfileID}`,
      requestData,
      configBooking()
    );
  } else {
    return axios.post(
      `https://hc.azure-api.net/fidelio/profile/${property}`,
      requestData,
      configBooking()
    );
  }
};

export const getPromoCode = (url: string) => {
  return axios.get(url);
};

export const getUser = async () => {
  const token = localStorage.getItem('customer_id_token') || localStorage.getItem('token');
  if (!token) {
    return;
  }

  const token_payload = JSON.parse(atob(token.split('.')[1]));
  const uuid = token_payload.id;

  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Bearer ' + token
    }
  };

  const status = axios
    .get(`https://loyalty.humancompany.cloud/api/customer/${uuid}/status`, requestOptions)
    .catch((error) => {
      if (error.response.status === 401) {
        localStorage.removeItem('customer_id_token');
        localStorage.removeItem('token');
      }
    });

  const customer = axios.get(
    `https://loyalty.humancompany.cloud/api/customer/${uuid}`,
    requestOptions
  );

  return axios.all([status, customer]);
};

export const createReservation = (orders: IOrder[]) => {
  for (const order of orders) {
    // @ts-ignore
    order.GuestArrival = dayjs(order.GuestArrival).format('YYYY-MM-DD');
    // @ts-ignore
    order.GuestDeparture = dayjs(order.GuestDeparture).format('YYYY-MM-DD');
  }
  return axios.post(
    'https://api.humancompany.cloud/api/v3/reservation-multiple',
    orders,
    configBookingWithCredentials()
  );
};

export const getOpeningsDates = (url: string) => {
  return axios.get(url);
};

/*export const getMinStay = (website: string, guestArrival: any, guestDeparture: any) => {
  const property = propertyByWebsite(website);
  return axios.get(
    `https://api.humancompany.cloud/api/v2/rates/${property}/min-stay?GuestArrival=${guestArrival}&GuestDeparture=${guestDeparture}`
  );
};*/

export const getAvailabilityRequests = (
  properties: IProperty[],
  searchForms: ISearchForm,
  source: any
): any[] => {
  const propertiesFiltered = getPropertiesBySearchForm(properties, searchForms);
  const requestUrls: Set<string> = new Set();
  const requests = [];

  for (const property of propertiesFiltered) {
    const requestURL= `https://hc.azure-api.net/HuBOL/getAvailability?property=${
      property.property
    }&NoOfAdults=${searchForms.NoOfAdults}&children=${
      searchForms.ageOfChildren
    }&GuestArrival=${dayjs(searchForms.GuestArrival).format(
      'YYYY-MM-DD'
    )}&GuestDeparture=${dayjs(searchForms.GuestDeparture).format('YYYY-MM-DD')}`;

    if (searchForms.Coupon) {
      requestUrls.add(`${requestURL}&CouponCode=${searchForms.Coupon}`)
    } else {
      requestUrls.add(requestURL)
    }
  }

  for (const requestUrl of requestUrls) {
    const request = axios.get(requestUrl, { cancelToken: source.token, headers: headers });
    requests.push(request);
  }

  return Array.from(requests);
};

export const getAvailabilityRequest = (
  property: string,
  searchForm: ISearchForm,
  source: CancelTokenSource,
  grecaptcha: string | null,
  RateCode = null
) => {
  let urlRequestChildParams = '';
  const childCategories: any[] = Object.keys(searchForm.Child);

  let i = 0;
  for (const cat of childCategories) {
    urlRequestChildParams += `&Child[${i}][attr]=${cat}&Child[${i}][node]=${searchForm.Child[cat]}`;
    i++;
  }

  if (!searchForm.GuestArrival || !searchForm.GuestDeparture) {
    return Promise.reject();
  }

  // axios.defaults.timeout = 5000;

  if (RateCode) {
    return axios.get(
      `https://hc.azure-api.net/fidelio/availability/${property}?GuestArrival=${dayjs(searchForm.GuestArrival, 'YYYY-MM-DD').format(
        'DD.MM.YYYY'
      )}&GuestDeparture=${dayjs(searchForm.GuestDeparture, 'YYYY-DD-MM').format('DD.MM.YYYY')}&NoOfAdults=${
        searchForm.NoOfAdults
      }&&AvailabilityLimit=BookingOnline&WebOnly=0&RateCode=${RateCode}&array=true${urlRequestChildParams}&grecaptcha=${grecaptcha}`,
      {
        cancelToken: source.token
      }
    );
  }

  return axios.get(
    `https://hc.azure-api.net/fidelio/availability/${property}?GuestArrival=${dayjs(searchForm.GuestArrival, 'YYYY-MM-DD').format(
      'DD.MM.YYYY'
    )}&GuestDeparture=${dayjs(searchForm.GuestDeparture, 'YYYY-MM-DD').format('DD.MM.YYYY')}&NoOfAdults=${
      searchForm.NoOfAdults
    }&MultipleRates=1&array=true${urlRequestChildParams}&grecaptcha=${grecaptcha}`,
    {
      cancelToken: source.token
    }
  );
};

/*export const getRoomTypes = (website: string) => {
  const property = propertyByWebsite(website);
  return axios.get(`https://hc.azure-api.net/fidelio/v2/roomtype/${property}?array=true`);
};*/

export const searchReservation = (searchForm: ISearchReservationForm) => {
  if (!searchForm.GuestArrival || !searchForm.GuestDeparture) return Promise.reject();
  return axios.post(
    `https://hc.azure-api.net/fidelio/reservation/auth/id/${searchForm.property}/${
      searchForm.GuestNum
    }?GuestArrival=${dayjs(searchForm.GuestArrival, 'YYYY-MM-DD').format(
      'DD.MM.YYYY'
    )}&GuestDeparture=${dayjs(searchForm.GuestDeparture, 'YYYY-MM-DD').format('DD.MM.YYYY')}`
  );
};

export const getReservationsByToken = () => {
  const headers = {
    Authorization: localStorage.getItem('token_bookings_management')
  };
  return axios.get('https://hc.azure-api.net/fidelio/reservations-by-token', {
    headers: headers
  });
};

export const getAvailabilitySaved = (id: number, uuid: string) => {
  return axios.get(
    `https://api.humancompany.cloud/api/v3/waiting-room/availability-saved?request_id=${id}&uuid=${uuid}`
  );
};

export const validateToken = (property: string, token: string) => {
  return axios.post(`https://hc.azure-api.net/fidelio/v3/validate-recaptcha/${property}/${token}`);
};

export const getTimeEstimates = () => {
  const website = getCurrentWebsite();
  return axios.get('https://hc.azure-api.net/fidelio/v3/status?property=' + website);
};

interface IUseScriptOptions {
  async?: boolean;
  nomodule?: boolean;
  type?: 'module' | 'script';
  id?: string;
}

export const useScript = (url: string, options: IUseScriptOptions) => {
  const { async, nomodule, type, id } = options;
  const script = document.createElement('script');
  script.src = url;

  if (async) script.async = true;
  if (nomodule) script.noModule = true;
  if (type) script.type = type;
  if (id) script.id = id;

  if (id && !document.getElementById(id)) {
    script.id = id;
    document.head.appendChild(script);
  }
};

export const getProperties = async (lang = 'en'): Promise<AxiosResponse<IProperty[]>> => {
  return axios.get(`${import.meta.env.VITE_HUBOL_API_URL}/getProperties?pos=${website.code}`, {
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': lang
    }
  });
};

export const getCart = async (cart_id: string):Promise<AxiosResponse<IOrder[]>> => {
  return axios.get(`${endpoints.cart}/carts/${cart_id}`);
};

export const addItemToCart = async (cart_id: string, order: IOrderItem) => {
  return axios.post(`${endpoints.cart}/carts/${cart_id}`, order, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

export const deleteItemToCart = async (cart_id: string, orderId: string) => {
  return axios.delete(
    `${endpoints.cart}/carts/${cart_id}/${orderId}`,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
};

export const deleteAllCart = async (cart_id: string) => {
  return axios.delete(
    `${endpoints.cart}/carts/${cart_id}`,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
}

export const addPackageToItem = async (cart_id: string, orderId: string, pack: IPackages) => {
  return axios.post(
    `${endpoints.cart}/carts/${cart_id}/${orderId}/packages`,
    {
      headers: {
        'Content-Type': 'application/json'
      },
      package_code: pack.PackageCode
    }
  );
};
export const deletePackageToItem = async (cart_id: string, orderId: string, pack: string) => {
  return axios.delete(
    `${endpoints.cart}/carts/${cart_id}/${orderId}/packages/${pack}`
  );
};


/* ----------------------------------------------- SESSION -----------------------------------------------------------*/


interface ICheckoutSessionUrl {
  url: string
}


interface ICheckoutUrlOptions {
  success_url: string;
  cancel_url: string;
}
export const getCheckoutUrl = (cart_id: string, options: ICheckoutUrlOptions) => {
  return axios.post<ICheckoutSessionUrl>(`${endpoints.cart}/carts/${cart_id}/checkout`, options)
}


/*----------------------------------------------- VALIDATE -----------------------------------------------------------*/

export const cartValidate = (cart_id: string) => {
  return axios.get(`${endpoints.cart}/carts/${cart_id}/validate`)
}

export const getBookedItems = (id: string) => {
  return axios.get(`${endpoints.cart}/items-booked/${id}`);
}

export const setBookedItemsGA4 = (id: string) => {
  return axios.put(`${endpoints.cart}/items-booked/${id}`);
}

export const getExtraTime = (cart_id: string) => {
  return axios.post(`${endpoints.cart}/carts/${cart_id}/add-time`);
}

/*----------------------------------------------- PROFILE -----------------------------------------------------------*/

export const getProfile = (user_id: string) => {
  return axios.get(`${endpoints.cart}/profiles/${user_id}`);
}

export const getNotifications = (property: string, lang = 'en') => {
  return axios.get(`https://hc.azure-api.net/ms_${property}/notificationsfeed`, {
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': lang
    }
  })
}
